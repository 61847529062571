@font-face {
  font-family: 'freelance-icons-fonts';
  src:  url('fonts/freelance-icons-fonts.eot?4vpl0j');
  src:  url('fonts/freelance-icons-fonts.eot?4vpl0j#iefix') format('embedded-opentype'),
    url('fonts/freelance-icons-fonts.ttf?4vpl0j') format('truetype'),
    url('fonts/freelance-icons-fonts.woff?4vpl0j') format('woff'),
    url('fonts/freelance-icons-fonts.svg?4vpl0j#freelance-icons-fonts') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ico-"], [class*=" ico-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'freelance-icons-fonts' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ico-ico-envelope:before {
  content: "\e900";
}
.ico-ico-git-hub:before {
  content: "\e901";
}
.ico-ico-globe:before {
  content: "\e902";
}
.ico-ico-google-allo:before {
  content: "\e903";
}
.ico-ico-instagram:before {
  content: "\e904";
}
.ico-ico-isq:before {
  content: "\e905";
}
.ico-ico-phone:before {
  content: "\e906";
}
.ico-ico-pinterest:before {
  content: "\e907";
}
.ico-ico-signal:before {
  content: "\e908";
}
.ico-ico-skype:before {
  content: "\e909";
}
.ico-ico-slack:before {
  content: "\e90a";
}
.ico-ico-snapchat:before {
  content: "\e90b";
}
.ico-ico-telegram:before {
  content: "\e90c";
}
.ico-ico-threema:before {
  content: "\e90d";
}
.ico-ico-twitter:before {
  content: "\e90e";
}
.ico-ico-viber:before {
  content: "\e90f";
}
.ico-ico-whats-app:before {
  content: "\e910";
}
